<footer class="border-t border-gray-300" *ngIf="isLoginPage === false; else loginhPageFooter" id="footer">
    <div class="top-footer">
        <div class="su-container flex flex-col md:flex-row justify-between py-6 text-white">   
            <div class="footer-links mb-6 md:mb-0">
                <h3 class="font-bold mb-4">Onze organisatie</h3>
                <ul class="space-y-2">
                    <li><a href="#"><i class="fas fa-chevron-right mr-2"></i>Over ons</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right mr-2"></i>Algemene voorwaarden</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right mr-2"></i>Cookiebeleid</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right mr-2"></i>Privacybeleid</a></li>
                    <li><a href="#"><i class="fas fa-chevron-right mr-2"></i>Rechten van betrokkenen</a></li>
                </ul>
            </div>
    
            <!-- Company address -->
            <div class="footer-address">
                <h3 class="font-bold mb-4">Youvia b.v.</h3>
                <p>Herikerbergweg 88<br>1101CM Amsterdam</p>
            </div>
        </div>
    </div>
    <div class="bottom-footer">
        <div class="su-container flex flex-row py-6">   
            <div class="flex items-center md:gap-8 align-start md:flex-row flex-col">
                <a href="{{'footer.Fcrmedia' | translate}}" 
                    class="mb-4 md:mb-0 row-start-5 md:row-start-1" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    [ariaLabel]="'Fcrmedia' | translate">
                    <app-icon [icon]="'powered_by_youvia'" svgClass="w-24 h-12" svgFill="white"></app-icon>
                </a>
    
                <!-- message -->
                <div class="md:text-sm text-white text-center text-xs">
                    <p class="mb-0 md:block hidden">{{ footerMessage }}</p>
                </div>
            </div>
    
            <div class="lg:ml-auto flex items-center items-start lg:flex-row mt-2 pt-4 text-white text-sm w-full md:mt-0 md:pt-0 lg:w-auto">
                <div class="flex">
                    <a class="bg-white mr-3 p-2 rounded-full w-8 h-8" target="_blank" href="https://www.youvia.nl" rel="noopener noreferrer">
                        <app-icon [icon]="'youvia_icon'" svgClass="w-4 h-4" svgFill="#6F6F6F"></app-icon>
                    </a>
                    <a href="//www.facebook.com/youviaNL" class="bg-white mr-3 p-2 rounded-full w-8 h-8" target="_blank" rel="noopener noreferrer">
                        <app-icon [icon]="'facebook-icon'" svgClass="w-4 h-4" svgFill="#6F6F6F"></app-icon>
                    </a>
                    <a href="//www.linkedin.com/company/de-gouden-gids/" class="bg-white mr-3 p-2 rounded-full w-8 h-8" target="_blank" rel="noopener noreferrer">
                        <app-icon [icon]="'linkedin-icon'" svgClass="w-4 h-4" svgFill="#6F6F6F"></app-icon>
                    </a>
                </div>
            </div>
    
        </div>
    </div>
</footer>
<ng-template #loginhPageFooter>
    <footer class="border-t border-gray-300 bottom-footer">
        <div class="su-container flex flex-row py-6">   

            <div class="flex items-center md:gap-8 align-start md:flex-row flex-col">
                <a href="{{'footer.Fcrmedia' | translate}}" 
                    class="mb-4 md:mb-0 row-start-5 md:row-start-1" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    [ariaLabel]="'Fcrmedia' | translate">
                    <app-icon [icon]="'powered_by_youvia'" svgClass="w-24 h-12" svgFill="white"></app-icon>
                </a>

                <!-- message -->
                <div class="md:text-sm text-white text-center text-xs">
                    <p class="mb-0 md:block hidden">{{ footerMessage }}</p>
                </div>
            </div>

            <div class="lg:ml-auto flex items-center items-start lg:flex-row mt-2 pt-4 text-white text-sm w-full md:mt-0 md:pt-0 lg:w-auto">
                <div class="flex">
                    <a class="bg-white mr-3 p-2 rounded-full w-8 h-8" target="_blank" href="https://www.youvia.nl" rel="noopener noreferrer">
                        <app-icon [icon]="'youvia_icon'" svgClass="w-4 h-4" svgFill="#6F6F6F"></app-icon>
                    </a>
                    <a href="//www.facebook.com/youviaNL" class="bg-white mr-3 p-2 rounded-full w-8 h-8" target="_blank" rel="noopener noreferrer">
                        <app-icon [icon]="'facebook-icon'" svgClass="w-4 h-4" svgFill="#6F6F6F"></app-icon>
                    </a>
                    <a href="//www.linkedin.com/company/de-gouden-gids/" class="bg-white mr-3 p-2 rounded-full w-8 h-8" target="_blank" rel="noopener noreferrer">
                        <app-icon [icon]="'linkedin-icon'" svgClass="w-4 h-4" svgFill="#6F6F6F"></app-icon>
                    </a>
                </div>
            </div>
    
        </div>
    </footer>
</ng-template>