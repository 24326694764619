<!-- Sidebar -->
<aside class="w-64 h-screen bg-gray-100 flex flex-col pt-5 overflow-y-auto">
    <!-- Navigation Links -->
    <nav class="w-full">
        <a routerLink="/dash" class="flex items-center py-3 px-5 text-gray-700 hover:bg-gray-200 w-full">
            <i class="fas fa-th-large mr-3"></i>
            <span>Dashboard</span>
        </a>
        <a href="/profile" class="flex items-center py-3 px-5 text-gray-700 hover:bg-gray-200 w-full">
            <i class="fas fa-building mr-3"></i>
            <span>Company Profile</span>
        </a>
        <a href="/analytics" class="flex items-center py-3 px-5 text-gray-700 hover:bg-gray-200 w-full">
            <i class="fas fa-chart-line mr-3"></i>
            <span>Analytics</span>
        </a>
    </nav>
</aside>