<main id="main" role="main" class="main" >
    <app-header [isLoginPage]="isLoginPage"></app-header>
    <div class="main-content">
        <app-sidebar *ngIf="!this.isLoginPage"></app-sidebar>
        <div class="w-full mx-4 my-4">
            <router-outlet />
        </div>
    </div>
    <app-footer [isLoginPage]="isLoginPage"></app-footer>
</main>
