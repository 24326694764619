import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { StickyElementComponent } from './components/sticky-element/sticky-element.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { NgxTranslateModule } from './translate/translate.module';
import { ListingsComponent } from './pages/listings/listings.component';
import { IconComponent } from './components/icon/icon.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ButtonComponent } from './components/button/button.component';
import { ListingCardComponent } from './pages/listings/components/listing-card/listing-card.component';
import { LoginComponent } from './pages/login/login.component';
import { WithCredentialsInterceptor } from '@fcr/azure-auth';
import { AUTH_URL, BASE_URL } from './constants/endpoints';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { FormComponent } from './pages/listings/components/form/form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { PhoneInputComponent } from './pages/listings/components/form/phone-input/phone-input.component';
import { SocialInputComponent } from './pages/listings/components/form/social-input/social-input.component';
import { OpeningHoursComponent } from './pages/listings/components/form/opening-hours/opening-hours.component';
import { CountriesModalComponent } from './pages/listings/components/form/countries-modal/countries-modal.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CompanyProfileComponent } from './pages/dashboard/components/company-profile/company-profile.component';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    StickyElementComponent,
    SkeletonComponent,
    ListingsComponent,
    IconComponent,
    LanguageSwitchComponent,
    ButtonComponent,
    ListingCardComponent,
    LoginComponent,
    FormComponent,
    PhoneInputComponent,
    SocialInputComponent,
    OpeningHoursComponent,
    CountriesModalComponent,
    DashboardComponent,
    SidebarComponent,
    AnalyticsComponent,
    ProfileComponent,
    CompanyProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxTranslateModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatStepperModule,
    MatDividerModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatDialogModule,
    NgxMatSelectSearchModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'fcr-auth-api-url',
      useValue: AUTH_URL
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
