import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@fcr/azure-auth';
import { TranslateService } from '@ngx-translate/core';
import { PlausibleTrackingService } from '../../services/plausible-tracking.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  brand: string;
  locale: string;

  constructor(
    public authService: AuthService, 
    private translate: TranslateService, 
    private router: Router, 
    private title: Title,
    private plausible: PlausibleTrackingService) { }

  ngOnInit(): void {
    this.locale = this.translate.currentLang.substring(3)
    this.brand = "youvia"
    if(this.authService.isUserLoggedIn())
      this.router.navigateByUrl("/dash")
    this.listenForSignIn()
    this.title.setTitle("Mijnyouvia | Login")
  }

  listenForSignIn() {
    this.authService.loggedIn.subscribe(x => {
      this.router.navigateByUrl("/dash")
    })
  }

  handleLogIn() {
    // track
    this.plausible.trackCustomEvent("LoginClick");
    // login
    this.authService.login(this.locale, this.brand);
  }
}
