<div class="bg-white p-6 rounded-lg shadow-md w-full">
    <h2 class="text-2xl font-semibold mb-4">My Company Profile</h2>
    <p class="text-gray-600 mb-2">Location:</p>
    
    <!-- Select Input for Location -->
    <select class="w-full p-2 mb-4 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:border-blue-500">
        <option value="antwerp">FCR Media Antwerp Office - Uitbreidingstraat 82 - 2018 Antwerpen</option>
        <option value="brussels">FCR Media Brussels Office - Example Street 10 - 1000 Brussels</option>
        <!-- Add more options as needed -->
    </select>

    <div class="flex items-center mb-4">
        <span class="text-gray-600 mr-2">Profile Completion:</span>
        <div class="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
            <div class="absolute top-0 left-0 h-full bg-blue-500" style="width: 75%;"></div>
        </div>
        <span class="ml-2 text-gray-600">75%</span>
    </div>

    <div class="flex justify-between items-center mb-4">
        <p class="text-sm text-blue-500 cursor-pointer hover:underline">Tip: Add your opening hours (+10%)</p>
        <button class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">Edit</button>
    </div>

    <div class="flex justify-between items-center">
        <a href="#" class="text-blue-500 hover:underline">View Profile on goldenpages.be</a>
    </div>
</div>
